import React from 'react';
import Image from 'gatsby-image';
import { SocialProfileWrapper, SocialProfileItem } from './socialProfile.style';

const SocialProfile = ({ items, className }) => {
  const addAllClasses = ['social_profiles'];

  if (className) {
    addAllClasses.push(className);
  }

  return (
    <SocialProfileWrapper className={addAllClasses.join(' ')}>
      {items.map((item, index) => (
        <SocialProfileItem
          key={`social-item-${index}`}
          className="social_profile_item"
        >
          <a href={item.url || '#'} aria-label="social icon" target="_blank">
            <Image
              fluid={
                (item.image !== null) | undefined
                  ? item.image.childImageSharp.fluid
                  : {}
              }
              alt={`SocialImage-${index + 1}`}
            />
          </a>
        </SocialProfileItem>
      ))}
    </SocialProfileWrapper>
  );
};

export default SocialProfile;
